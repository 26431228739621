<template>
  <v-app class="app-container">
    <Header />

    <v-main class="main-content">
      <v-container class="content-container">
        <router-view />
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Header from './components/HeaderPage.vue'
import Footer from './components/FooterPage.vue'

export default {
  components: {
    Header,
    Footer
  },
  
};
</script>

<style>
.app-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh; /* make sure it is at least the height of the viewport */
}

.main-content {
  display: flex;
}

.content-container {
  flex-grow: 1;
}
</style>
