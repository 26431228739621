<template>
  <v-card color="lightgrey" class="mb-5">
  <v-card-title  class="text-center">
      <h2>Company Information</h2>
  </v-card-title>
  <v-card-text>    
      <!-- Company Name -->
      <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-text-field
          v-model="localCompanyName"
          label="Company Name"
          outlined
          required
          @input="updateCompanyName"
        />
      </v-col>
      </v-row>

      <!-- Company Information -->
      <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-textarea
          v-model="localCompanyInfo"
          :counter="250"
          label="Company Info"
          outlined
          required
          @input="updateCompanyInfo"
        />
      </v-col>
      </v-row>

      <!-- Contact Option -->
      <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-text-field
          v-model="localContactOption"
          label="Contact Option"
          outlined
          required
          @input="updateContactOption"
        />
      </v-col>
      </v-row>
  </v-card-text>   
    <v-card-actions>
      <v-btn color="primary" @click="handleSaveDataClick">Save</v-btn>
      <v-icon v-if="isDirty" color="warning">mdi-alert</v-icon>
    </v-card-actions> 
    <v-snackbar v-model="showSnackbar" :color="snackbarColor">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-card>

  <!-- Add Vuetify dialog component -->
  <v-dialog v-model="showDialog" persistent>
  <v-card>
    <v-card-title>Unsaved Changes</v-card-title>
    <v-card-text>
      You have unsaved changes. Are you sure you want to leave?
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="stay">Stay</v-btn>
      <v-btn color="red" @click="leave">Leave</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { ref, computed, onMounted, watch} from 'vue';
import { useStore } from 'vuex';
import {useRouter, onBeforeRouteLeave } from 'vue-router';

import useCompanyInfoService from '../services/useCompanyInfoService';
import useFirebaseAuthService from '../services/useFirebaseAuthService';


export default {
name: 'CompanyInfo',
props: {
    companyName: String,
    companyInfo: String,
    contactOption: String,
  },
setup(props) {
  const { handleAuthStateChange } = useFirebaseAuthService();

  const localCompanyName = ref(props.companyName);
  const localCompanyInfo = ref(props.companyInfo);
  const localContactOption = ref(props.contactOption);

  const { 
    validateAndSaveData, 
    status,
    isDirty, 
    error,
    validationMessage 
  } = useCompanyInfoService();

  const showSnackbar = ref(false);
  const showDialog = ref(false);
  const snackbarColor = ref('');
  const router = useRouter();
  const store = useStore();  
  const nextRoute = computed(() => store.state.nextRoute);

  watch(
      () => props.companyName,
      (newVal) => {
        console.log("companyName prop changed:", newVal);
        localCompanyName.value = newVal;
      },
      { immediate: true }
    );

    watch(
      () => props.companyInfo,
      (newVal) => {
        console.log("companyInfo prop changed:", newVal);
        localCompanyInfo.value = newVal;
      },
      { immediate: true }
    );

    watch(
      () => props.contactOption,
      (newVal) => {
        console.log("contactOption prop changed:", newVal);
        localContactOption.value = newVal;
      },
      { immediate: true }
    );


  const updateCompanyName = () => {
    store.commit('updateContactInfo', { companyName: localCompanyName.value });
    };

  const updateCompanyInfo = () => {
    store.commit('updateContactInfo', { companyInfo: localCompanyInfo.value });
  };

  const updateContactOption = () => {
    store.commit('updateContactInfo', { contactOption: localContactOption.value });
   };

const snackbarMessage = computed(() => {

  // return validation message if it's not empty
  if (validationMessage.value !== '') {
    return validationMessage.value;
  }

  switch(status.value) {
    case 'success': return 'Data saved successfully';
    case 'error': return error.value;
    default: return '';
  }
});

const handleSaveDataClick = async () => {
      const result = await validateAndSaveData();
      showSnackbar.value = true; // show the snackbar after data is saved
      snackbarColor.value = result ? 'green' : 'red';
    };

  // Call handleAuthStateChange when the component is created
  onMounted(handleAuthStateChange);


const stay = () => {
  showDialog.value = false;
};

const leave = () => {
  showDialog.value = false;
  // If nextRoute.value is null or empty, redirect to '/' (or any default path you want)
  let route = nextRoute.value || '/';
  router.push(route);
  store.commit('setIsDirty', false); // Reset the isDirty flag in the store

};


onBeforeRouteLeave((to, from, next) => {
  if (isDirty.value) {
    showDialog.value = true;
    store.commit('setNextRoute', to.path);
    next(false);
  } else {
    next();
  }
});

return { 
      error, 
      localCompanyName,
      localCompanyInfo,
      localContactOption,
      showSnackbar, 
      snackbarMessage, 
      snackbarColor, 
      isDirty, 
      showDialog, 
      stay, 
      leave, 
      handleSaveDataClick, 
      handleAuthStateChange, 
      status,
      updateCompanyName,
      updateCompanyInfo,
      updateContactOption
    };
 },

//OLD ONE
};
</script>
