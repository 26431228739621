<template>
    <v-app>
  
  <v-main>
        <v-container>
              <!-- Here the content of the current route will be rendered -->
              <!-- <router-vie  w /> -->
  
          <!-- Instructions -->
          <v-row>
            <v-col cols="12">
              <h1 class="text-center">
                Instant 
                <span>{{ dynamicBuzzWord }}</span>
                <span class="blinking-cursor">_</span>Review Responses</h1>
              <p class="text-center">
                Just copy & paste a Trustpilot review into the box below and generate a professional response to a review manually, right here, right now.
              </p>
          </v-col>
          </v-row>
<!-- Manual Response Generation Section -->
<!-- <v-row class="manual-section header-with-background">
  <v-col cols="12" class="text-center">
    <div class="content-container">
      <h1 class="section-title">
        Instant <span>{{ dynamicBuzzWord }}</span><span class="blinking-cursor">_</span>Review Responses
      </h1>
      <p class="section-description">
        Generate a professional response to a review manually, right here, right now.
      </p>
    </div>
  </v-col>
</v-row> -->


             

          <!-- Platform Selection -->
          <PlatformSelection 
            :buzzWords="buzzWords" 
            v-model="selectedBuzzWord"
          />
  
          <v-row>        
          <!-- Company Information Section --> 
          <v-col cols="12" md="4">
            <CompanyInfo 
              :companyName="companyName"
              :companyInfo="companyInfo"
              :contactOption="contactOption"
              @update:companyName="updateCompanyName"
              @update:companyInfo="updateCompanyInfo"
              @update:contactOption="updateContactOption"
            />
          </v-col>
  
          <!-- Review Processing Section -->
          <v-col cols="12" md="8">
            <v-card>
              <v-card-title  class="text-center">
                <h2>Review Processing</h2>
              </v-card-title>
              <v-card-text>
  
            <!-- Input Area -->
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-textarea
                  v-model="inputReview"
                  :label="'Paste your ' + selectedBuzzWord + ' review here'"            
                  outlined
                  rows="5"
                  required
                ></v-textarea>
              </v-col>
            </v-row>
  
            <!-- Star Rating -->
            <div class="text-center">
            <p class="rating-description">Please select the rating of the review:</p>
              <v-rating
                v-model="rating"
                hover
                bg-color="orange-lighten-1"
                color="gold"
              ></v-rating>
            </div>
  
  
            <!-- Generate Button -->
            <v-row>
              <v-col cols="12" class="text-center">
                <!-- <v-btn large color="secondary" @click="generateResponse">Generate</v-btn> -->
                <v-btn large color="secondary" @click="generateResponse" v-if="!loading">Generate</v-btn>
                <v-progress-circular v-else indeterminate color="secondary"></v-progress-circular>  
              </v-col>
            </v-row>
  
            <!-- Output Area -->
            <v-row>
              <v-col cols="12" md="8" offset-md="2">
                <v-textarea
                  v-model="generatedResponse"
                  label="Generated Response"
                  outlined
                  rows="5"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
  
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="generateResponse">Generate</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

         <!-- Subscription Service Section -->
        

<v-row class="highlight-section">
    <v-col cols="12" class="text-center content-container">
        <h2 class="highlight-heading">Automate Your Responses Like a Pro!</h2>
        <p class="highlight-text">With our subscription service, perfectly-crafted responses to customer reviews are posted automatically.</p>
        <p class="highlight-text">
            Want hands-free excellence? Subscribe to our automated response service and never worry about replying to reviews again - we've got it covered.
        </p>
        <!-- Call to Action -->
        <router-link to="/about">
          <v-btn large class="custom-btn learn-more">Learn More</v-btn>
        </router-link>
        
        <router-link to="/register">
          <v-btn large class="custom-btn get-started">Get Started - Register</v-btn>
        </router-link>
    </v-col>
</v-row>


  
        </v-container>
      </v-main>
  
      
    </v-app>
  </template>
  
  
  <script>
  import axios from 'axios';
  
  import CompanyInfo from './CompanyInfo.vue';
  import PlatformSelection from './PlatformSelection.vue';
  import useCompanyInfoService from '../services/useCompanyInfoService';
  import useSubscriptionInfoService from '../services/useSubscriptionInfoService';
  import firebase from '../services/firebaseConfig';
  import { onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
    
  
  export default {
    name: 'HomePage',
    components: {
        CompanyInfo,
        PlatformSelection
    },

    setup() {
      const store = useStore();
      const { fetchCompanyData } = useCompanyInfoService();
      const { fetchSubscriptionPlan } = useSubscriptionInfoService();

      const loadUserData = async () => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          console.log(`Fetching data for user: ${currentUser.uid}`);
          await fetchCompanyData();
          console.log('After fetchCompanyData call');
          await fetchSubscriptionPlan();
          console.log('After fetchSubscriptionPlan call');
          setTimeout(() => {
            console.log('After fetch: selectedBuzzWord from Store:', store.state.platformInfo.selectedBuzzWord);
            console.log('After fetch: Company Name from Store:', store.state.contactInfo.companyName);
            console.log('After fetch: Company Info from Store:', store.state.contactInfo.companyInfo);
            console.log('After fetch: Contact Option from Store:', store.state.contactInfo.contactOption);
          }, 1000); // Verzögerung, um sicherzustellen, dass die Daten gesetzt sind
 
        } else {
          console.log('No user is logged in.');
        }
      };

    const initializeAuth = async () => {
      return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged((user) => {
          console.log('Auth state changed. Current user:', user);
          resolve(user);
        });
      });
    };

    onMounted(async () => {
      const user = await initializeAuth();
      if (user) {
        await loadUserData();
      } else {
        console.log('No user is logged in.');
      }
    });

    const companyName = computed(() => {
      console.log('Company Name from Store:', store.state.contactInfo.companyName);
      return store.state.contactInfo.companyName;
    });

    const companyInfo = computed(() => {
      console.log('Company Info from Store:', store.state.contactInfo.companyInfo);
      return store.state.contactInfo.companyInfo;
    });

    const contactOption = computed(() => {
      console.log('Contact Option from Store:', store.state.contactInfo.contactOption);
      return store.state.contactInfo.contactOption;
    });

    const selectedBuzzWord = computed({
      get() {
        console.log('selectedBuzzWord from Store:', store.state.platformInfo.selectedBuzzWord);
        return store.state.platformInfo.selectedBuzzWord;
      },
      set(value) {
        console.log('selectedBuzzWord set:', value);
        store.commit('updatePlatformInfo', { selectedBuzzWord: value });
      },
    });



      

      return {
        store,
        companyName,
        companyInfo,
        contactOption,
        selectedBuzzWord,
      };
    },

    data() {
      return {
        dynamicBuzzWord: '',
        buzzWords: ['Trustpilot', 'Google', 'Facebook'],
        wordIndex: 0,
        letterIndex: 0,
        isDeleting: false,
        typingSpeed: 200,
        inputReview: '',
        generatedResponse: '',
        rating: 1 ,
        loading: false,
        personTypingImage: require('../assets/personTypingKeyboard.jpg'),
     
      };
    },
  
    mounted() {
      this.typeWords();
    },
  
    methods: {
      updateCompanyName(value) {
        this.$store.commit('updateContactInfo', { companyName: value });
      },
      updateCompanyInfo(value) {
        this.$store.commit('updateContactInfo', { companyInfo: value });
      },
      updateContactOption(value) {
        this.$store.commit('updateContactInfo', { contactOption: value });
      },
  
        generateResponse() {
        this.loading = true;
        // gather all the necessary data
        let data = {
          selectedBuzzWord: this.selectedBuzzWord,
          companyName: this.companyName,
          companyInfo: this.companyInfo,
          contactOption: this.contactOption,
          inputReview: this.inputReview,
          rating: this.rating,
          // include any other data that you need
        };

        // send a POST request to your server generateReviewResponse
        //axios.post('http://178.254.42.205:55555/generateReviewResponse', data, {
        axios.post('https://v76530.1blu.de/api/generateReviewResponse', data, {
          headers: {
            'Authorization': 'jhsefdT7NtRlq2hf0cKCMEdd2S375eHZp7Ty9f1xoDdFuUaBYapuwmu9kAZ0VmRs63nJjU69OqBc19HlHSYd4Mc2h2mYe500QEWUJuKOTeJPgcJ69zuoNtVU3kTKdZBx' // replace with your actual key
          }
        })
          .then(response => {      
            this.generatedResponse = response.data.trim();
            this.loading = false;
          })
          .catch(error => {
            // handle the error
            console.error(error);
            this.loading = false;
          });
      },
  
      typeWords() {
        const currentWord = this.buzzWords[this.wordIndex];
        if (this.isDeleting) {
          this.dynamicBuzzWord = currentWord.substring(0, this.letterIndex - 1);
          this.letterIndex--;
          if (this.letterIndex === 0) {
            this.isDeleting = false;
            this.wordIndex = (this.wordIndex + 1) % this.buzzWords.length;
          }
        } else {
          this.dynamicBuzzWord = currentWord.substring(0, this.letterIndex + 1);
          this.letterIndex++;
          if (this.dynamicBuzzWord === currentWord) {
            this.isDeleting = true;
          }
        }
  
        let newTypingSpeed = this.isDeleting ? this.typingSpeed / 3 : this.typingSpeed;
        if (this.dynamicBuzzWord === currentWord) newTypingSpeed = 3000; // pause for 1 sec at end of word
  
        setTimeout(this.typeWords, newTypingSpeed);
      },
  
    },
  };
  </script>
  
  <style scoped>
  .v-textarea {
    margin-bottom: 30px;
  }
  
  .blinking-cursor {
    animation: blink 1s step-end infinite;
  }
  
  @keyframes blink {
    from,
    to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }

  .video-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.video-wrapper video {
  max-width: 50%;
}
.header-with-background {
  background-image: url('../assets/personTypingKeyboard.jpg');
  background-position: center;
  background-size: cover;
  padding: 60px 20px; /* You can adjust the padding to change the height of the section */
  position: relative;
  color: #ffffff; /* Change text color to white for better contrast */
}

.section-title, .section-description {
  position: relative;
  z-index: 1;
}

/* Optionally, you can add an overlay to make text more readable */
.header-with-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* This is a black overlay with 50% opacity */
}

.highlight-section {
    background-color: #f9f9f9; /* Light grey background color */
    margin: 40px 0;
    padding: 40px 0;
}

.content-container {
    max-width: 800px;
    margin: auto;
}

.highlight-heading {
    font-size: 32px; /* You can adjust the font-size */
    font-weight: bold;
    margin-bottom: 20px;
}

.highlight-text {
    font-size: 18px; /* You can adjust the font-size */
    margin-bottom: 30px;
}

.custom-btn {
    font-size: 16px;
    margin: 0 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
}

/* .custom-btn:hover {
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    transform: translateY(-7px);
} */

.learn-more {
    background-color: #4CAF50; /* This is green */
    color: white;
}

.get-started {
    background-color: #2196F3; /* This is blue */
    color: white;
}

.learn-more:hover {
    box-shadow: 0px 15px 20px #4CAF50;;
    transform: translateY(-7px);
}

.get-started:hover {
    box-shadow: 0px 15px 20px #2196F3;
    transform: translateY(-7px);
}
.navigation-links a {
    color: #333;
    text-decoration: none;
    margin: 0 10px;
}

.navigation-links a:hover {
    text-decoration: underline;
}



  </style>
  