// useAuth.js
import { ref, computed } from 'vue';
import firebase from './firebaseConfig'; // import firebase instance
import { useRouter } from 'vue-router';  // import Vue Router

const user = ref(null);
const isAuthenticated = computed(() => user.value !== null);

export default function useAuth() {
    const router = useRouter();  // get router instance inside function

    const unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
        user.value = firebaseUser;
       });

    const signIn = async (email, password) => {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        router.push({ path: '/' });  // navigate to the home page
    };
    
    const signOut = async () => {
        await firebase.auth().signOut();
        router.push({ path: '/' });  // navigate to the home page
    };
    
    const register = async (email, password) => {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        router.push({ path: '/' });  // navigate to the profile page after registration
    };
    
    return { user, isAuthenticated, signIn, signOut, register, unsubscribe };
}
