<template>
  <!-- Registration Form -->
  <v-form @submit.prevent="loginUser">
    <v-text-field
      v-model="email"
      label="Email"
      type="email"
      required
    ></v-text-field>
    <v-text-field
      v-model="password"
      label="Password"
      type="password"
      required
    ></v-text-field>
    <v-btn type="submit">Login</v-btn>
    <v-alert closable v-if="errorMessage" :title="errorTitle" :text="errorMessage" type="error"></v-alert>
    </v-form>
</template>

<script>
import { ref } from 'vue';
import useAuth from '../services/useAuth';

export default {
  setup() {
    const { signIn } = useAuth();
    const email = ref("");
    const password = ref("");
    const errorTitle = ref("");
    const errorMessage = ref("");

    const loginUser = async () => {
      errorTitle.value = "";
      errorMessage.value = "";

      try {
        await signIn(email.value, password.value);
      } catch (error) {
        switch (error.code) {
          case 'auth/invalid-email':
            errorTitle.value = "Login failed";
            errorMessage.value = "Invalid email";
            break
          case 'auth/user-not-found':
            errorTitle.value = "Login failed";
            errorMessage.value = "No account with that email was found";
            break
          case 'auth/wrong-password':
            errorTitle.value = "Login failed";
            errorMessage.value = "Incorrect password";
            break
          default:
            errorTitle.value = "Login failed";
            errorMessage.value = "Email or password was incorrect";
            break
        }
      }
    }

    return { email, password, errorTitle, errorMessage, loginUser };
  }
};
</script>
