import firebase from './firebaseConfig';
import useFirestore from './useFirestore';
import { useStore } from 'vuex';

export default function useSubscriptionInfoService() {
  const store = useStore();
  const { error, status, addDoc, getDoc } = useFirestore('subscriptions');
  const { addDoc: addEmailDoc } = useFirestore('emails');

  const saveSubscriptionInfo = async () => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const userId = currentUser.uid;
      console.log(`Saving subscription info for user: ${userId}`);
      try {
        const subscriptionData = {
          id: userId,
          companyId: userId,
          selectedSubscriptionPlan: store.state.subscriptionPlan,
          ...store.state.platformInfo,
          ...store.state.subscriptionInfo,
          ...store.state.contactInfo,
        };
        await addDoc(subscriptionData);
        console.log('Subscription data saved successfully');

        // Zusätzliche E-Mail-Daten speichern
        const emailData = {
          to: [
            {
              email: 'hermann@wechselfabrik.de',
              name: 'EaseOfAI'
            }
          ],
          from: {
            email: 'Hermann@ecaachen.de',
            name: 'Review Reply'
          },
          subject: 'Anmeldung bei EaseOfAI!',
          html: 'Neue Anmeldung bei review Reply EaseOfAi!!',
          text: 'Neue Anmeldung bei review Reply EaseOfAi!!',
          reply_to: {
            email: 'reply_to@example.com',
            name: 'Reply to name'
          }
        };

        await addEmailDoc(emailData);
        console.log('Email data saved successfully');
        
      } catch (err) {
        console.error('Error saving subscription or email data:', err);
      }
    } else {
      console.error('No current user found');
    }
  };

  const fetchSubscriptionPlan = async () => {
    console.log(`fetchSubscriptionPlan fetchSubscriptionPlan`);
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const userId = currentUser.uid;
      console.log(`Fetching subscription data for user: ${userId}`);
      const data = await getDoc(userId);
      console.log('Fetched data:', data);
  
      if (data) {
        store.commit('updateSubscriptionPlan', data.selectedSubscriptionPlan);
        console.log('Updating platform info with data:', data);
        store.commit('updatePlatformInfo', data);
        store.commit('updateSubscriptionInfo', data);
        console.log('Selected BuzzWord after commit:', store.state.platformInfo.selectedBuzzWord);
      } else {
        console.warn('No subscription plan found');
        store.commit('updateSubscriptionPlan', null);
      }
    } else {
      console.error('No current user found');
    }
  };
  

  return {
    saveSubscriptionInfo,
    fetchSubscriptionPlan,
    status,
    error,
  };
}
