// router.js

import { createRouter, createWebHistory } from 'vue-router';
import useAuth from '../services/useAuth';
import Home from '../components/HomePage.vue';
import Profile from '../components/ProfileUser.vue';
import Imprint from '../components/imprint.vue'; 
import About from '../components/aboutSection.vue';
import Pricing from '../components/PricingSection.vue';
import RegisterUser from '../components/RegisterUser.vue';


const routes = [
  { path: '/', component: Home },
  { path: '/profile', component: Profile, meta: { requiresAuth: true } },
  { path: '/imprint', component: Imprint },
  { path: '/about', component: About },
  { path: '/pricing', component: Pricing },
  { path: '/register', component: RegisterUser },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
});

router.beforeEach((to, from, next) => {

  const { isAuthenticated } = useAuth();

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated.value) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next('/');
  } else {
    next(); // make sure to always call next()!
  }
});




export default router;
