import { createStore } from 'vuex';

export default createStore({
  state: {
    isDirty: false,
    nextRoute: null,
    subscriptionPlan: 'Free - I reply on my own',
    stepsComplete: [false, false, false],
    platformInfo: {
      buzzWords: ['Trustpilot', 'Google', 'Facebook'],
      selectedBuzzWord: 'Trustpilot',
      platformUsername: '',
      platformPassword: '',    
      alert: '',
    },
    companyInfo: {
      companyName: '',
      companyInfo: '',
      contactOption: '',
    },
    contactInfo: {
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
    },
    subscriptionInfo: {
      country: '',
      street: '',
      streetNumber: '',
      postalCode: '',
      city: '',
      state: '',
      vatNumber: '',
    },
  },

  mutations: {
    setIsDirty(state, isDirty) {
      state.isDirty = isDirty;
    },

    setNextRoute(state, nextRoute) {
      state.nextRoute = nextRoute;
    },

    updateSubscriptionPlan(state, plan) {
      state.subscriptionPlan = plan;
    },

    setStepComplete(state, { stepIndex, isComplete }) {
      state.stepsComplete[stepIndex] = isComplete;
    },

    updatePlatformInfo(state, newPlatformInfo) {
      console.log('updatePlatformInfo called with:', newPlatformInfo);
      state.platformInfo = { ...state.platformInfo, ...newPlatformInfo };
    },

    updateContactInfo(state, newContactInfo) {
      console.log('updateContactInfo called with:', newContactInfo);
      state.contactInfo = { ...state.contactInfo, ...newContactInfo };
    },
    updateCompanyInfo(state, newCompanyInfo) {
      state.companyInfo = { ...state.companyInfo, ...newCompanyInfo };
    },

    updateSubscriptionInfo(state, newSubscriptionInfo) {
      console.log('updateSubscriptionInfo called with:', newSubscriptionInfo);
  
      state.subscriptionInfo = { ...state.subscriptionInfo, ...newSubscriptionInfo };
    },
  },
});
