<template>
    <v-row class="mt-5 justify-center align-center">
      <v-col cols="12" md="6">
        <h3 class="text-center mb-1">Choose your platform:</h3>
        <v-select
          :items="buzzWords"
          v-model="selectedBuzzWord"
          label="Platform"
          outlined
          required
          class="mx-auto" 
          style="width: 60%"
        ></v-select>
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    props: ['buzzWords', 'modelValue'],
    emits: ['update:modelValue'],
    computed: {
      selectedBuzzWord: {
        get() {
          console.log('selectedBuzzWord in PlatformSelection get:', this.modelValue);
  
          return this.modelValue;
        },
        set(value) {
          console.log('selectedBuzzWord in PlatformSelection set:', value);
   
          this.$emit('update:modelValue', value);
        },
      },
    },
  }
  </script>
  