<!-- Header.vue -->
<template>
    <v-app-bar app color="secondary" dark>

      <!-- <v-toolbar-title @click="$router.push('/')">EaseOfAI</v-toolbar-title> -->
      <v-toolbar-title class="cursor-pointer hover-effect" @click="$router.push('/')">EaseOfAI</v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link to="/about">
        <v-btn text>About</v-btn>
      </router-link>

      <router-link to="/pricing">
        <v-btn text>Pricing</v-btn>
      </router-link>

      
      <!-- Profile button, visible only when user is logged in -->
      <span v-if="user">
          <router-link to="/profile">
              <v-btn text>
                  <span>Profile</span>
              </v-btn>
          </router-link>
      </span>


      <!-- Add a Logout button whitch only shows if the user is logged in-->
      <span v-if="user">
        <v-btn text @click="signOut">
          <span>Logout</span>
        </v-btn>
      </span>

      <!-- Add a login button only shows if the user is not logged in -->
      <dialogBox  v-if="!user">
          <template #title>
            Login
          </template>
          <template #content>
            <loginUser />
          </template>
      </dialogBox >

      <!-- Add a register button only shows if the user is not logged in  -->
      <dialogBox  v-if="!user">
        <template #title>
          Register
        </template>
        <template #content>
          <registerUser />
        </template>
      </dialogBox >
      
    </v-app-bar>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';
import useAuth from '../services/useAuth';

import registerUser from './RegisterUser.vue';
import loginUser from './LoginUser.vue';
import dialogBox from './DialogBox.vue';

export default {
  name: 'HeaderPage',


  components: {
    registerUser,
    loginUser,
    dialogBox
  },

  setup() {
    const { user, signOut, unsubscribe } = useAuth();

    onMounted(() => {
      // subscribe to auth state changes
      unsubscribe();
    });

    onUnmounted(() => {
      // unsubscribe from auth state changes
      unsubscribe();
    });

    return { user, signOut };
  }
}
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }

  .hover-effect:hover {
    color: #5a9; /* Change to whatever color you prefer */
  }

  .hover-effect:active {
    color: #a95; /* Change to whatever color you prefer */
  }
</style>
