<template>
  <div>
    <div class="d-flex justify-center align-center">
      <h1>Welcome to your profile</h1>
    </div>
    <!-- Company Information Section -->
    <CompanyInfo
      :companyName="companyName"
      :companyInfo="companyInfo"
      :contactOption="contactOption"
      @update:companyName="updateCompanyName"
      @update:companyInfo="updateCompanyInfo"
      @update:contactOption="updateContactOption"
    />


    <!-- User Subscription Plan Section -->
    <SubscriptionPlan :initial-selection="subscriptionPlan" />

    <!-- Subscription Stepper -->
    <v-card v-if="showSteps">
      <v-card-text>
        <div class="step-indicator">
          <v-progress-linear v-model="step" :max="3" height="20" color="secondary"></v-progress-linear>
        </div>
        <div class="step-content">
          <div v-show="step === 1">
            <v-card-title class="justify-center"><h2>Step 1 of 3</h2></v-card-title>
            <UserPlatformInfo />
          </div>
          <div v-show="step === 2">
            <v-card-title class="justify-center"><h2>Step 2 of 3</h2></v-card-title>
            <ContactInformation />
          </div>
          <div v-show="step === 3">
            <v-card-title class="justify-center"><h2>Step 3 of 3</h2></v-card-title>
            <SubscriptionInfo />
          </div>
        </div>
        <div class="step-actions">
          <v-btn @click="prev" :disabled="step === 1">Previous</v-btn>
          <v-btn @click="next" :disabled="step === 3 || !isCurrentStepComplete">Next</v-btn>
          <v-btn color="primary" @click="save" v-if="step === 3" :disabled="!isCurrentStepComplete">Save</v-btn>
        </div>
      </v-card-text>

      <v-dialog v-model="showConfirmationDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>
            By clicking "Order with obligation to pay", you confirm that you are subscribing to a paid subscription. Are you sure you want to proceed?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showConfirmationDialog = false">Abort</v-btn>
            <v-btn color="primary" text @click="submitOrder">Order with obligation to pay</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- notification snackbar -->
      <v-snackbar v-model="showSnackbar" :color="snackbarColor">{{ snackbarText }}</v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed  } from 'vue';
import { useStore } from 'vuex';
import CompanyInfo from './CompanyInfo.vue';
import UserPlatformInfo from './UserPlatformInfo.vue';
import SubscriptionInfo from './SubscriptionInfo.vue';
import SubscriptionPlan from './SubscriptionPlan.vue';
import ContactInformation from './ContactInformation.vue';
import useSubscriptionInfoService from '../services/useSubscriptionInfoService';
import useCompanyInfoService from '../services/useCompanyInfoService';
import firebase from '../services/firebaseConfig';

export default {
  name: 'ProfileUser',
  components: {
    CompanyInfo,
    UserPlatformInfo,
    SubscriptionInfo,
    SubscriptionPlan,
    ContactInformation,
  },
  setup() {
    const store = useStore();
    const { saveSubscriptionInfo, status, error, fetchSubscriptionPlan } = useSubscriptionInfoService();
    const { fetchCompanyData } = useCompanyInfoService();
    const showSnackbar = ref(false);
    const snackbarText = ref('');
    const snackbarColor = ref('success');
    const showConfirmationDialog = ref(false);
    const step = ref(1);

    const initializeAuth = async () => {
      return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged((user) => {
          console.log('Auth state changed. Current user:', user);
          resolve(user);
        });
      });
    };

    const loadUserData = async () => {
      console.log('Fetching company data and subscription plan...');
      await fetchCompanyData();
      await fetchSubscriptionPlan();
      console.log('Company Info from Store after fetch:', store.state.contactInfo);
      console.log('Subscription Plan from Store:', store.state.subscriptionPlan);
     // Zusätzliche Überprüfung, ob die Daten korrekt im Store geladen wurden
  setTimeout(() => {
    console.log('Company Info after delay:', store.state.contactInfo);
    console.log('Subscription Plan after delay:', store.state.subscriptionPlan);
  }, 1000); // 1 Sekunde Verzögerung

    };


    const submitOrder = async () => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        console.log(`Submitting order for user: ${currentUser.uid}`);
        await saveSubscriptionInfo();
        if (status.value === 'success') {
          snackbarText.value = 'Subscription ordered successfully';
          snackbarColor.value = 'success';
          showSnackbar.value = true;
          showConfirmationDialog.value = false;
        } else {
          snackbarText.value = 'Failed to save data: ' + error.value;
          snackbarColor.value = 'error';
          showSnackbar.value = true;
          showConfirmationDialog.value = false;
        }
      } else {
        console.error('No current user found');
      }
    };

    onMounted(async () => {
      const user = await initializeAuth();
      if (user) {
        await loadUserData();
      } else {
        console.log('No user is logged in.');
      }
    });


    const showSteps = ref(false);

    // Watch the subscriptionPlan for changes to update showSteps
    watch(
      () => store.state.subscriptionPlan,
      (newPlan) => {
        showSteps.value = newPlan && newPlan !== 'Free - I reply on my own';
      },
      { immediate: true }
    );

      // Hier die neuen Computed Properties hinzufügen
  const companyName = computed(() => store.state.contactInfo.companyName);
  const companyInfo = computed(() => store.state.contactInfo.companyInfo);
  const contactOption = computed(() => store.state.contactInfo.contactOption);


    return {
      step,
      submitOrder,
      showSnackbar,
      snackbarText,
      snackbarColor,
      showConfirmationDialog,
      status,
      error,
      showSteps,
      companyName,
      companyInfo,
      contactOption,
    };
  },
  computed: {
    subscriptionPlan() {
      console.log("Subscription Plan from Store:", this.$store.state.subscriptionPlan);
      return this.$store.state.subscriptionPlan;
    },
    isCurrentStepComplete() {
      return this.$store.state.stepsComplete[this.step - 1];
    },

  },
  methods: {
    next() {
      if (this.step < 3) this.step += 1;
    },
    prev() {
      if (this.step > 1) this.step -= 1;
    },
    save() {
      this.showConfirmationDialog = true;
    },
    updateCompanyName(value) {
      this.$store.commit('updateContactInfo', { companyName: value });
    },
    updateCompanyInfo(value) {
      this.$store.commit('updateContactInfo', { companyInfo: value });
    },
    updateContactOption(value) {
      this.$store.commit('updateContactInfo', { contactOption: value });
    }
  },
};
</script>

<style scoped>
.step-indicator {
  margin-bottom: 10px;
}
.step-content {
  margin-bottom: 10px;
  margin-top: 10px;
}
.step-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.v-card-title {
  padding-top: 5px;
  padding-bottom: 10px; /* Adds some space between the text and the card above */
  font-size: 1rem; /* Adjusts the size of the text */
  font-weight: bold; /* Makes the text bold */
  text-align: center; /* Centers the text */
}
/* ORGINAL */
</style>