<template>
  <div>
    <v-btn text @click="openDialog">
    <span class="headline">          
        <slot  name="title"></slot>
    </span>
    </v-btn>

    <v-dialog v-model="dialogVisible" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">          
            <slot  name="title"></slot>
          </span>
        </v-card-title>

        <v-card-text>
          <slot  name="content"></slot>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>
