<template>
  <!-- Registration Form -->
  <v-form @submit.prevent="registerUser">
    <v-text-field
      v-model="email"
      label="Email"
      type="email"
      required
    ></v-text-field>
    <v-text-field
      v-model="password"
      label="Password"
      type="password"
      required
    ></v-text-field>
    <v-btn type="submit">Register</v-btn>
  </v-form>
</template>

<script>
import { ref } from 'vue';
import useAuth from '../services/useAuth';

export default {
  setup() {
    const { register } = useAuth();
    const email = ref("");
    const password = ref("");

    const registerUser = async () => {
      try {
        await register(email.value, password.value);
      } catch (error) {
        console.error('Error', error);
      }
    }

    return { email, password, registerUser };
  }
};

</script>
