<template>
  <v-container>
    <!-- Heading -->
    <v-row class="mb-5" align="center">
      <v-col>
        <h1 class="text-center about-title">Choose the Plan That Suits You Best</h1>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-row>
      <v-col md="12">
        <!-- Pricing Section -->
        <div class="content-section">
          <v-row align="center" class="text-center">
            <v-col cols="12">
              <v-icon class="highlight-icon" color="primary" large>mdi-currency-usd-circle-outline</v-icon>
              <h2 class="section-title">Our Subscription Plans</h2>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-for="(item, index) in items" :key="index" cols="12" sm="6" md="4" lg="3" class="d-flex justify-center py-4">
              <v-card class="mx-auto pricing-card elevation-5">
                <v-card-title class="card-title">
                  <div>{{ item.title }}</div>
                  <div>{{ item.subtitle }}</div>
                </v-card-title>
                <v-card-text class="card-content">{{ item.description }}</v-card-text>
                <v-card-actions class="center-actions">
                  <v-btn color="primary" text @click="selectPlan">Select Plan</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Registration Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Register</v-card-title>
        <v-card-text>
          <register-user />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '../services/useAuth';
import registerUser from './RegisterUser.vue';

export default {
  name: 'PricingSection',
  components: {
    registerUser
  },
  setup() {
    const { user } = useAuth();
    const dialog = ref(false);
    const router = useRouter(); // Correct way to use the router in setup

    const selectPlan = () => {
      if (!user.value) {
        dialog.value = true;
      } else {
        router.push('/profile'); // Correct way to navigate
      }
    }

    return {
      items: [
        { title: 'Free', subtitle: 'I reply on my own', description: 'Ideal for businesses who want to manage their own responses.' },
        { title: '$10 per month', subtitle: 'up to 10 automatic replies', description: 'Perfect for small businesses starting to gather customer reviews.' },
        { title: '$45 per month', subtitle: 'up to 50 automatic replies', description: 'For growing businesses with a regular flow of customer reviews.' },
        { title: '$185 per month', subtitle: 'unlimited automatic replies', description: 'The ultimate plan for businesses receiving a high volume of reviews.' }
      ],
      dialog,
      selectPlan
    }
  }
}
</script>

<style scoped>
.pricing-card {
  max-width: 460px; /* Increase max-width */
  text-align: center;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 350px; /* Ensure the cards are tall enough to fit the content */
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.center-actions {
  justify-content: center;
  margin-top: auto; /* Push actions to the bottom */
}

.card-content {
  word-wrap: break-word;
  padding: 0 16px;
  flex-grow: 1; /* Allow content to grow */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-title div:first-child {
  font-weight: bold;
  font-size: 1.5em;
}

.card-title div:last-child {
  font-size: 1em;
  color: grey;
  word-wrap: break-word;
}

.about-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
}

.section-title {
  font-size: 2em;
  font-weight: bold;
  margin-top: 20px;
}

.highlight-icon {
  font-size: 4em;
  margin-bottom: 20px;
}

.v-btn {
  font-weight: bold;
}

/* Flexibility for smaller screens */
@media (max-width: 600px) {
  .pricing-card {
    min-height: 400px; /* Increase minimum height for smaller screens */
  }

  .card-title div:first-child {
    font-size: 1.2em;
  }

  .card-title div:last-child {
    font-size: 0.9em;
  }

  .card-content {
    padding: 0 10px;
  }
}
</style>
