<!-- Footer.vue -->
<template>
  <v-footer color="secondary" dark>
    <v-container>
      <v-row>
        <v-col class="text-center">
          &copy; {{ new Date().getFullYear() }} - EaseOfAI
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <router-link to="/imprint">Imprint & data protection</router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
  
<script>
export default {
  name: 'FooterPage'
}
</script>
