import firebase from './firebaseConfig';
import useFirestore from './useFirestore';
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

export default function useCompanyInfoService() {
  const originalData = ref({
    companyName: '',
    companyInfo: '',
    contactOption: ''
  });
  const store = useStore();  
  const { error, status, addDoc, getDoc } = useFirestore('companies');
  const isDirty = computed(() => store.state.isDirty);
  const validationMessage = ref(''); 

  // Fetch user data
  const fetchCompanyData = async () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      console.log('Fetching company data for user:', userId);
      const userData = await getDoc(userId);
      console.log('Fetched company data:', userData);

      if (userData) {
        store.commit('updateContactInfo', {
          companyName: userData.companyName,
          companyInfo: userData.companyInfo,
          contactOption: userData.contactOption,
        });

        // Store original data
        originalData.value = {
          companyName: userData.companyName,
          companyInfo: userData.companyInfo,
          contactOption: userData.contactOption,
        };

        // Initialize watcher here, after the originalData has been populated
        watch(() => store.state.contactInfo, (newData) => {
          if (
            newData.companyName !== originalData.value.companyName ||
            newData.companyInfo !== originalData.value.companyInfo ||
            newData.contactOption !== originalData.value.contactOption
          ) {
            store.commit('setIsDirty', true);
          } else {
            store.commit('setIsDirty', false);
          }
        });
        // Reset dirty flag
        store.commit('setIsDirty', false);

      } else {
        console.log('No user data found');
      }
    } else {
      console.log('No user is logged in. Redirect to login or show a message.');
    }
  };
  


  // Call fetchCompanyData when the component is created
  onMounted(fetchCompanyData);

  const validateAndSaveData = async () => {
    const { companyName, companyInfo, contactOption } = store.state.contactInfo;
    console.log('Validating data:', { companyName, companyInfo, contactOption });

    if (companyName === '' || companyInfo === '') {
      validationMessage.value = 'Please fill out all the data before saving.';
      status.value = 'error';
      return false;
    }
    validationMessage.value = '';

    // Save the data to firestore.
    await addDoc({
      id: firebase.auth().currentUser.uid,
      companyName,
      companyInfo,
      contactOption,
    });

    if (status.value === 'success') {
      validationMessage.value = 'Data saved successfully';
    }

    // Update original data and reset dirty flag
    originalData.value = {
      companyName,
      companyInfo,
      contactOption,
    };

    store.commit('setIsDirty', false);
    return status.value === 'success';
  };
  
  return { 
    fetchCompanyData, 
    validateAndSaveData, 
    status,
    isDirty, 
    error,
    validationMessage 
  };
}
//ORG