<template>
    <div>
      <h2 class="text-center mb-5">Subscription Plan</h2>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-select
            v-model="subscriptionPlanSelected"
            :items="['Free - I reply on my own','$10 per month - up to 10 automatic replies', '$50 per month - up to 50 automatic replies', '$250 per month - unlimited automatic replies']"
            label="Subscription Plan"
            outlined
            required            
            @change="updateSubscriptionPlanAction"
          >
          <template v-slot:append>
            <v-badge
              color="green"
              content="Active"
              overlap
              :value="subscriptionPlanSelected !== 'Free - I reply on my own'"
            >
              <v-icon>mdi-check</v-icon>
            </v-badge>
          </template>
        </v-select>
        </v-col>
      </v-row>
    </div>
  </template>
  
<script>

    import useSubscriptionInfoService from '../services/useSubscriptionInfoService';

    export default {
    name: 'SubscriptionPlan',    
    props: ['initialSelection'],

    created() {
      const service = useSubscriptionInfoService();

      // Fetch subscription plan
      service.fetchSubscriptionPlan().then(() => {  
        this.subscriptionPlanSelected = this.$store.getters.getSubscriptionPlan;
      });
    },

    computed: {
      subscriptionPlanSelected: {
        get() {
          return this.$store.state.subscriptionPlan;
        },
        set(value) {
          this.$store.commit('updateSubscriptionPlan', value);
        }
      }
    },

    methods: {
      updateSubscriptionPlanAction() {
        this.$store.dispatch('updateSubscriptionPlanAction', this.subscriptionPlanSelected);
      },
    },

    watch: {
      '$store.state.subscriptionPlan': function(newValue, oldValue) {
        console.log('Subscription plan changed from', oldValue, 'to', newValue);
      }
    }

    
    }
</script>
  