<!-- Impressum.vue -->
<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <h1>Legal Disclosure</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Information in accordance with Section 5 TMG
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        ECA Energy Consulting Aachen GmbH
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Hofer Str. 11, 93057 Regensburg
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Register Number: HRB 25001 Court of Registration: Aachen
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Executives: Christoph Hermann
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <h1>Privacy Policy</h1> <!-- Datenschutz in English -->
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the ECA Energy Consulting Aachen GmbH. The use of the Internet pages of the ECA Energy Consulting Aachen GmbH is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        The data controller responsible for handling your personal data is ECA Energy Consulting Aachen GmbH.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        We collect and process personal data for the purposes of registration and login to the customer portal and for subscription services. This data is necessary for us to execute the requested service and to generate an accurate invoice. The legal basis for this data processing is the performance of a contract (Article 6(1)(b) GDPR).
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Our Data Protection Officer can be contacted via email at: Hermann@ecaachen.de
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        The data we collect is stored on a Firebase server located in Frankfurt, Germany. In addition, the data necessary to execute the ordered service is stored on a separate server. We do not transfer your data outside of the European Union.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        We retain your data for as long as necessary to execute the ordered service. Once the service is cancelled, we will delete your data.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        Under GDPR, you have the right to access, correct, or delete your personal data. You also have the right to restrict processing or object to processing, as well as the right to data portability. If you wish to exercise these rights, please send an email to our Data Protection Officer at info@ecaachen.de.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        If you wish to withdraw your consent, please contact our Data Protection Officer via email at info@ecaachen.de.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        If you have a complaint about how we handle your data, you can contact the Data Protection Authority in Germany.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        We do not knowingly collect data from children under the age of 16.
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        For further information and/or requests, you can contact us via email at info@ecaachen.de.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ImpressumPage'
}
</script>
