// useFirebaseAuthService.js
import firebase from './firebaseConfig';
import useCompanyInfoService from './useCompanyInfoService';

export default function useFirebaseAuthService() {
  const { fetchCompanyData } = useCompanyInfoService();

  const handleAuthStateChange = () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetchCompanyData();
      }
      // Add your logic for unauthenticated state
    });
  };

  return { handleAuthStateChange };
}
