import firebase from './firebaseConfig'; // import firebase instance
import { ref } from 'vue';

export default function useFirestore(collection) {
  const error = ref(null);  
  const status = ref('');

  const addDoc = async (data) => {
    const db = firebase.firestore();

    try {
      console.log(`Adding document to collection: ${collection}, with data:`, data);
      const docRef = await db.collection(collection).doc(data.id);
      await docRef.set(data);
      console.log('Document added successfully');
      error.value = null;
      status.value = 'success';
    } catch (err) {
      console.error('Error adding document:', err);
      error.value = 'Failed to save data';
      status.value = 'error';
    }
  };

  const getDoc = async (id) => {
    const db = firebase.firestore();

    try {
      console.log(`Fetching document from collection: ${collection}, with ID: ${id}`);
      const docRef = db.collection(collection).doc(id);
      const doc = await docRef.get();
      if (doc.exists) {
        console.log('Document data:', doc.data());
        status.value = 'success';
        return doc.data();
      } else {
        console.log("No such document!");
        error.value = 'Document not found';
        status.value = 'error';
        return null;
      }
    } catch (err) {
      console.error('Error fetching document:', err);
      error.value = 'Failed to fetch data';
      status.value = 'error';
      return null;
    }
  };

  return { error, status, addDoc, getDoc };
}
