<template>
  <v-container>

    <!-- Heading -->
    <v-row class="mb-5" align="center">
      <v-col>
        <h1 class="text-center about-title">Unleashing the Power of AI in Review Management</h1>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-row>
      <v-col md="12">


         <!-- First Section -->
        <div class="content-section">
          <v-row no-gutters align="center" class="text-center">
            <v-col cols="12">
              <v-icon class="highlight-icon" color="primary" large>mdi-account-group-outline</v-icon>
              <h2 class="section-title">Revolutionize Your Review Management</h2>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <p class="section-content">
                <span class="highlight-text">Unlock the power of AI</span> to respond to customer reviews effortlessly. Our service automatically <span class="highlight-text">crafts and posts</span> professional responses to reviews on platforms like Trustpilot, Google, or Facebook.
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <v-col cols="12" class="text-center">
              <v-btn large class="custom-btn third-button" href="/register">Get Started - Register NOW</v-btn>
            </v-col>
          </v-row>
        </div>

        
        <v-row no-gutters justify="center">
    <v-col cols="12" md="8" class="video-container">
        <video class="responsive-video" autoplay loop muted>
            <source src="Trustplot_reviews.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </v-col>
</v-row>





        <!-- Second Section -->
        <div class="content-section second-section">
        <v-row no-gutters align="center" class="text-center">
            <v-col cols="12">
                <v-icon class="highlight-icon" color="primary" large>mdi-thumb-up-outline</v-icon>
                <h2 class="section-title">Building Trust and Credibility</h2>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <!-- List Section -->
                <v-list class="transparent-list">
                    <v-list-item-group>
                        <!-- Item 1 -->
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="blue">mdi-account-group</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="list-item-content">
                                <v-list-item-title class="list-item-title"><strong>Engagement:</strong></v-list-item-title>
                                <v-list-item-subtitle class="multi-line-subtitle">
                                    Quick responses demonstrate that you value customer feedback, encouraging further engagement and better customer retention.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- Item 2 -->
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="green">mdi-message-text-outline</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="list-item-content">
                                <v-list-item-title class="list-item-title"><strong>Reputation Management:</strong></v-list-item-title>
                                <v-list-item-subtitle class="multi-line-subtitle">
                                    Positive or negative, how you respond to reviews shapes your online image. Thoughtful responses can turn even negative reviews into opportunities.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <!-- Item 3 -->
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="red">mdi-search-web</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="list-item-content">
                                <v-list-item-title class="list-item-title"><strong>SEO Benefits:</strong></v-list-item-title>
                                <v-list-item-subtitle class="multi-line-subtitle">
                                    Search engines favor businesses that engage with their customers. Regular responses to reviews can improve your online visibility.
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
        <v-row no-gutters align="center" class="button-row">
            <v-col cols="12" class="text-center">
                <v-btn large class="custom-btn second-button" href="/register">Get Started - Register NOW</v-btn>
            </v-col>
        </v-row>
    </div>

   <!-- Third Section -->
<div class="content-section third-section">
  <v-row no-gutters align="center" class="text-center">
    <v-col cols="12">
      <v-icon class="highlight-icon" color="primary" large>mdi-chart-line</v-icon>
      <h2 class="section-title">Reap the Benefits</h2>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center">
    <!-- Speed Card -->
    <v-col cols="12" sm="6" md="4" class="py-2 card-column">
      <v-card class="mx-auto benefit-card">
        <v-card-title class="card-title">
          <v-icon large color="blue">mdi-speedometer</v-icon>
          <span class="title-text">Speed</span>
        </v-card-title>
        <v-card-text class="card-content">
          Our service guarantees fast responses, ensuring that your customers feel heard without delay.
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Quality Card -->
    <v-col cols="12" sm="6" md="4" class="py-2 card-column">
      <v-card class="mx-auto benefit-card">
        <v-card-title class="card-title">
          <v-icon large color="green">mdi-quality-high</v-icon>
          <span class="title-text">Quality</span>
        </v-card-title>
        <v-card-text class="card-content">
          Leveraging advanced AI technology, the responses are not just fast, but also high-quality and tailored to the review's content and rating.
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Cost-Effective Card -->
    <v-col cols="12" sm="6" md="4" class="py-2 card-column">
      <v-card class="mx-auto benefit-card">
        <v-card-title class="card-title">
          <v-icon large color="purple">mdi-currency-usd</v-icon>
          <span class="title-text">Cost-Effective</span>
        </v-card-title>
        <v-card-text class="card-content">
          Save on human resources. Our service is like having a dedicated PR team without the overhead costs.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row no-gutters justify="center">
    <!-- Hands-Free Card -->
    <v-col cols="12" sm="6" md="4" class="py-2 card-column">
      <v-card class="mx-auto benefit-card">
        <v-card-title class="card-title">
          <v-icon large color="orange">mdi-hand-heart</v-icon>
          <span class="title-text">Hands-Free</span>
        </v-card-title>
        <v-card-text class="card-content">
          The automated nature means you can focus on what you do best while we handle your online interactions.
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Customization Card -->
    <v-col cols="12" sm="6" md="4" class="py-2 card-column">
      <v-card class="mx-auto benefit-card">
        <v-card-title class="card-title">
          <v-icon large color="red">mdi-puzzle-edit-outline</v-icon>
          <span class="title-text">Customization</span>
        </v-card-title>
        <v-card-text class="card-content">
          The responses are adaptable and can be customized to align with your brand voice.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row no-gutters align="center" class="button-row">
    <v-col cols="12" class="text-center">
      <v-btn large class="custom-btn first-button" href="/register">Get Started - Register NOW</v-btn>
    </v-col>
  </v-row>
</div>



      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  name: 'AboutSection',

}
</script>

<style scoped>
.about-title {
  font-size: 3em;
  color: #2C3E50;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.5em;
  color: #34495E;
  margin-bottom: 10px;
}

.section-content {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: left;
  text-align: center;
}

.content-section {
  margin-bottom: 40px;
  text-align: center;
}

.highlight-icon {
  font-size: 2em;
  margin-bottom: 5px;
}

.section-title {
  font-size: 1.6em;
  color: #34495E;
  font-weight: bold;
}

.section-content {
  font-size: 1.1em;
  line-height: 1.6;
  color: #606060;
}

.highlight-text {
  color: #2C3E50;
  font-weight: bold;
}

.custom-btn {
    font-size: 16px;
    margin: 0 10px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
}

.custom-btn:hover {
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    transform: translateY(-7px);
}

.first-button {
    background-color: #4CAF50; /* This is green */
    color: white;
}
.first-button:hover {
    box-shadow: 0px 15px 20px #4CAF50;
}

.second-button {
    background-color: #2196F3; /* This is blue */
    color: white;
}
.second-button:hover {
    box-shadow: 0px 15px 20px #2196F3;
}

.third-button {
    background-color: #6200EE; /* This is primary color */
    color: white;
}
.third-button:hover {
    box-shadow: 0px 15px 20px #6200EE;
}

.second-section {
  background-color: #f9f9f9;
  padding: 20px;
}


.second-section {
    background-color: #f9f9f9;
    padding: 20px;
}

.list-item-content .v-list-item__subtitle {
    white-space: normal;
    overflow: visible;
    text-overflow: initial;
}

.transparent-list {
    background-color: transparent;
}

.multi-line-subtitle {
    white-space: normal;
    display: block;
    font-size: 1.1em; 
    line-height: 1.6;
}

.list-item-title {
    font-size: 1.2em;
    line-height: 1.6;
}

.benefit-card {
  max-width: 350px;
  height: 150px;
  display: flex;
  flex-direction: column;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: center; /* center the title and icon horizontally */
  text-align: center; /* align text to the center */
}

.title-text {
  font-size: 1.2em;
  margin-left: 10px;
}

.card-content {
  flex-grow: 1;
  text-align: center; /* Optional: To center the content text as well */
}

.card-column {
  margin-right: 10px;
  margin-left: 10px;
}

.video-container {
    width: 100%;
}

.responsive-video {
    width: 100%;
    height: auto;
}




</style>
