<template>
  <v-card color="lightgrey" class="mb-5">
  <v-card-title  class="text-center">
      <h2>Platform Information</h2>
  </v-card-title>
  <div class="text-center px-2 mb-n11">This information will be used solely to authenticate with the selected platform and post review responses on your behalf.</div>
  
  <v-card-text>    
      <!-- Platform Selection -->
      <PlatformSelection
        :buzzWords="buzzWords"
        v-model="platformInfo.selectedBuzzWord"
        @update:modelValue="handlePlatformSelection"
      />
      <v-alert closable type="info" v-if="alert">
        {{ alert }}
      </v-alert>

  
       <!-- Platform Username -->
      <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-text-field
          v-model="platformInfo.platformUsername"
          :label="platformInfo.selectedBuzzWord ? `${platformInfo.selectedBuzzWord} Username` : 'Platform Username'"
          outlined
          required          
          @input="handleInput('platformUsername')"
        ></v-text-field>
      </v-col>
      </v-row>

      <!-- Platform Password -->
      <v-row>
      <v-col cols="12" md="10" offset-md="1">
          <v-text-field
          v-model="platformInfo.platformPassword"
          type="password"
          :label="platformInfo.selectedBuzzWord ? `${platformInfo.selectedBuzzWord} Password` : 'Platform Password'"   
          outlined
          required
          hint="Please use a unique password that you do not use on other platforms."
          @input="handleInput('platformPassword')"
          ></v-text-field>
       </v-col>
      </v-row>
  </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import PlatformSelection from './PlatformSelection.vue';


export default {
name: 'PlatformInfo',
components: {
  PlatformSelection
},

data() {
  return {
    buzzWords: ['Trustpilot', 'Google', 'Facebook'], 
    alert: '',
  }
},

computed: {
  ...mapState({
    platformInfo: state => state.platformInfo,
  }),
},

methods: {
  ...mapMutations(['updatePlatformInfo']),

  handlePlatformSelection(newBuzzWord) {
    if (newBuzzWord === 'Trustpilot') {
        this.updatePlatformInfo({selectedBuzzWord: newBuzzWord}); 
        this.alert = '';
      } else {
        this.alert = `${newBuzzWord} is not supported at the moment.`;
      }

    this.checkFormComplete();
  },

  handleInput(field) {
    this.updatePlatformInfo({ [field]: this.platformInfo[field] });
    this.checkFormComplete();
  },

  checkFormComplete() {
    const isComplete = !!((this.platformInfo.selectedBuzzWord === 'Trustpilot') && this.platformInfo.platformUsername && this.platformInfo.platformPassword);
    this.$store.commit('setStepComplete', { stepIndex: 0, isComplete });
  },
},

}
</script>

<style scoped>
.small-text {
font-size: 0.8em;
color: #666;
display: block;
margin-top: 5px;
margin-bottom: 15px;
}
</style>
