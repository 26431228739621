import firebase from 'firebase/compat/app'; 
import 'firebase/compat/auth'; 
import 'firebase/compat/firestore';



/* code from our Firebase console */
var firebaseConfig = {
  apiKey: "AIzaSyCM6UZpb0IrUBNvFbBKcrBFM3lhn8LwRg4",
  authDomain: "rating-review-ai.firebaseapp.com",
  projectId: "rating-review-ai",
  storageBucket: "rating-review-ai.appspot.com",
  messagingSenderId: "729584353969",
  appId: "1:729584353969:web:e64dd7efecec932b645890"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;