<template>
  <v-card color="lightgrey" class="mb-5">
    <v-card-title class="text-center">
      <h2>Contact Information</h2>
    </v-card-title>
    <v-card-text>    
      
      <!-- Company Name -->
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-text-field
            v-model="contactInfo.companyName"
            label="Company Name"
            outlined
            required
            @input="handleInput('companyName')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Contact Person -->
      <v-row>
        <v-col cols="6" md="5" offset-md="1">
          <v-select
            v-model="contactInfo.salutation"
            :items="['Mr.', 'Ms.', 'Mrs.', 'Dr.', 'Prof.']"
            label="Salutation"
            outlined
            required
            @change="handleInput('salutation')"
          ></v-select>
        </v-col>
        <v-col cols="6" md="5">
          <v-text-field
            v-model="contactInfo.firstName"
            label="First Name"
            outlined
            required
            @input="handleInput('firstName')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="10" offset-md="1">
          <v-text-field
            v-model="contactInfo.lastName"
            label="Last Name"
            outlined
            required
            @input="handleInput('lastName')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Email Address -->
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-text-field
            v-model="contactInfo.email"
            label="Email Address"
            outlined
            required
            @input="handleInput('email')"
          ></v-text-field>
        </v-col>
      </v-row>
      
      <!-- Other necessary fields -->
    </v-card-text>
  </v-card>
</template>
  
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ContactInfo',

  computed: {
  ...mapState({
    contactInfo: state => state.contactInfo,
  }),
},

  methods: {
    ...mapMutations(['updateContactInfo']),

    handleInput(field) {
      console.log(`handleInput called for field: ${field} with value: ${this.contactInfo[field]}`);
      this.updateContactInfo({ [field]: this.contactInfo[field] });
      this.checkFormComplete();
    },

    checkFormComplete() {
      const isComplete = !!(this.contactInfo.companyName && this.contactInfo.firstName && this.contactInfo.lastName && this.contactInfo.salutation && this.contactInfo.email);
      this.$store.commit('setStepComplete', { stepIndex: 1, isComplete });
    },
  }
}
</script>

  