<template>
  <v-card color="lightgrey" class="mb-5">
    <v-card-title class="text-center">
      <h2>Billing Information</h2>
    </v-card-title>
    <v-card-text>    
          
      <!-- Address -->
      <v-row>
          <v-col cols="12" md="10" offset-md="1">
              <v-text-field
                  v-model="subscriptionInfo.country"
                  label="Country"
                  outlined
                  required
                  @input="handleInput('country')"
              ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="10" offset-md="1">
          <v-text-field
              v-model="subscriptionInfo.street"
              label="Street"
              outlined
              required
              @input="handleInput('street')"
          ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="5" offset-md="1">
          <v-text-field
              v-model="subscriptionInfo.streetNumber"
              label="Street Number"
              outlined
              required
              @input="handleInput('streetNumber')"
          ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
          <v-text-field
              v-model="subscriptionInfo.postalCode"
              label="Postal Code/ZIP"
              outlined
              required
              @input="handleInput('postalCode')"
          ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12" md="5" offset-md="1">
          <v-text-field
              v-model="subscriptionInfo.city"
              label="City"
              outlined
              required
              @input="handleInput('city')"
          ></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
          <v-text-field
              v-model="subscriptionInfo.state"
              label="State/Province"
              outlined
              required
              @input="handleInput('state')"
          ></v-text-field>
          </v-col>
      </v-row>

      <!-- VAT Number -->
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-text-field
            v-model="subscriptionInfo.vatNumber"
            label="VAT Identification Number"
            outlined
            @input="handleInput('vatNumber')"
          ></v-text-field>
        </v-col>
      </v-row>
      
      <!-- Other necessary fields -->
    </v-card-text>
  </v-card>
</template>
  
<script>

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SubscriptionInfo',
  
  computed: {
    ...mapState({
      subscriptionInfo: state => state.subscriptionInfo,
    }),
  },

  methods: {
    ...mapMutations(['updateSubscriptionInfo']),

    checkFormComplete() {
      const isComplete = !!(this.subscriptionInfo.country && this.subscriptionInfo.street && this.subscriptionInfo.streetNumber && this.subscriptionInfo.postalCode && this.subscriptionInfo.city && this.subscriptionInfo.state && this.subscriptionInfo.vatNumber);
      this.$store.commit('setStepComplete', { stepIndex: 2, isComplete });
    },

    handleInput(field) {
      this.updateSubscriptionInfo({ [field]: this.subscriptionInfo[field] });
      this.checkFormComplete();
    },

  },

  

}
</script>

  